<template>
    <div class="w-100" v-show="loaded"> 
    <JobBillOfLadingComponent :jobItem="jobItem" :clauses="clauses" :colours="colours" :hscodes="hscodes" :currencies="currencies" :countries="countries" :index="0" :ports="ports" :pendingUnits="pendingUnits" :offices="offices"
        ref="billofladingref" :surveyers="surveyers" :containerTypes="containerTypes" :blItem="billOfLading" :loaded="loaded" :loadingCountry="polCountry" :dischargeCountry="podCountry" :selectedBooking="selBooking" :active="formActive" />
    </div>
    <div v-show="!loaded">
        <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}}
    </div>

</template>

<script>
    import portService from '@/services/PortService';
    import relationService from "@/services/RelationService";
    import commodityService from "@/services/CommodityService";
    import currencyService from "@/services/CurrencyService";
    import clauseService from "@/services/ClauseService";
    import colourService from "@/services/ColourService";
    import hscodeService from "@/services/HscodeService";
    import dataStore from '@/store/data'
    import store from '@/store/user'
    import JobBillOfLadingComponent from '@/components/jobs/BillOfLadingComponentNew.vue';
   
    export default {
        name: 'BillOfLadingFormNew',
        props: ['itemData'],
        components: {
            JobBillOfLadingComponent
        },
        data() {
            return {
                billOfLading: {
                    "job_units": [],
                    "shipper": { "address": {} },
                    "consignee": { "address": {} },
                    "notify": { "address": {} },
                    "job_unit_containers": [],
                    "job_booking_voyage_manifest": [],
                    "freight": ""
                },
                carrier_ids:[],
                commodities: [],
                currencies:[],
                currenciesById:[],
                selectedBL: 0,
                ports: [],
                countries: [],
                surveyers: [],
                hscodes: [],
                clauses: [],
                colours: [],
                suppliers: [],
                containerTypes: [],
                quantityOptions: [],
                conditionOptions: [],
                polCountry: {},
                podCountry: {},
                isEditing: false,
                submitted: false,
                loading: false,
                pendingUnits: false,
                invalid: {},
                surcharges: [],
                offices: [],
                jobItem: null,
                selBooking: null,
                loaded: false,
                dataItemsLoaded: 0,
                dataItemsToLoad: 7,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.billOfLading = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                const bl = this.billOfLading;
                const is_final = bl.bill_of_lading_status_id == 3;
                //Tab 1
                if((!bl.payment_option || !bl.bl_type || !bl.release_type) && bl.bill_of_lading_status_id > 2){
                    alert(this.$t('errors.bl'));
                    document.getElementById("generaldets-tab-0").click();
                    return false;
                }
                /*if(this.$refs.billofladingref.hasSurveyedUnits(bl) && !bl.surveyor_id){
                    alert(this.$t('errors.bl_2'));
                    document.getElementById("containers-tab-0").click();
                    return false;
                }*/
                if(bl.in_transit && !bl.country_id){
                    alert(this.$t('errors.bl_3'));
                    document.getElementById("generaldets-tab-0").click();
                    return false;
                }
                if(bl.job_booking_voyage_manifest && bl.job_booking_voyage_manifest.find(manifest => manifest.is_nmt_bl === null)){
                    alert(this.$t('errors.bl_4'));
                    document.getElementById("generaldets-tab-0").click();
                    return false;
                }
                if(!bl.shipper_name && !bl.shipper){
                    alert("Cannot save a BL without address information");
                    document.getElementById("generaldets-tab-0").click();
                    return false;
                }
                const shippername = bl.shipper_name ? bl.shipper_name.toUpperCase() : bl.shipper.address.toUpperCase();
                if(!bl.shipper_name){
                    bl.shipper_name = shippername;
                }
                if(is_final && bl.bl_type == 'Original'){
                    //const consigneename = bl.consignee_name ? bl.consignee_name.toUpperCase() : bl.consignee.address.toUpperCase();
                    //if(shippername == "TO ORDER" || consigneename == "TO ORDER"){
                    //    alert(this.$t('errors.bl_5'));
                    //    return false;
                    //}
                }
                const requiredQuery = is_final ? "#generaldets-0 input:not([type='checkbox'])" : ("#generaldets-0 .col-6.position-relative input:not([type='checkbox']), #generaldets-0 .col-6.position-relative .vue-tel-input");
                let isValid = true;
                let required = document.querySelectorAll(requiredQuery);
                for(let field of required){
                    let value = field.value;
                    if(field.tagName == "DIV"){
                        value = field.children[1].value;
                    }
                    if((field.required || field.classList.contains('required')) && !value){
                        field.classList.add('is-invalid');
                        isValid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                    document.getElementById("generaldets-tab-0").click();
                    return false;
                }
                //Tab 2
                if(!bl.voyage_name || !bl.port_of_loading_name || !bl.port_of_discharge_name || (!bl.customer_id && !bl.customer_name) || !bl.office_id || !bl.operated_by_office_id){
                    alert(this.$t('errors.empty_fields'));
                    document.getElementById("iteniary-tab-0").click();
                    const els = document.querySelectorAll('#iteniary-0 input, #iteniary-0 select');
                    console.log(els);
                    els.forEach(field => {
                        if((field.required || field.classList.contains('required')) && (!field.value || field.value == this.$t('form.select'))){
                            field.classList.add('is-invalid');
                        }
                    })
                    return false;
                }
                //Tab 3
                if(bl.job_units.length == 0){
                    alert("This BL has no units. Please add at least one unit.");
                    document.getElementById("containers-tab-0").click();
                    return false;
                }
                let invalidUnitTab = 0;
                const ref = this.$refs.billofladingref;
                for(let uindex in bl.job_units){
                    const unit = bl.job_units[uindex];
                    invalidUnitTab = uindex;
                    isValid = unit.chassis_number && unit.weight && unit.volume;
                    if(ref.makeModelRequired && (!unit.unit_make_id || !unit.unit_model_id)){
                        isValid = false;
                    }
                    else if(ref.buildYearRequired && !unit.construction_year){
                        isValid = false;
                    }
                    else if(ref.colourRequired && !unit.colour_id){
                        isValid = false;
                    }
                    else if(ref.hsCodeRequired && !unit.hs_code){
                        isValid = false;
                    }
                    else if(ref.licensePlateRequired && !unit.license_plate){
                        isValid = false;
                    }

                    if(!isValid){
                        break;
                    }
                }
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                    document.getElementById("containers-tab-0").click();
                    this.$nextTick(() => {
                        document.getElementById('uli-'+bl.id+'-'+invalidUnitTab).click();
                        window.setTimeout(() => {
                            const req = document.querySelectorAll('#containers-0 .unit-details-section input[required], #containers-0 .unit-details-section select[required]');
                            req.forEach(el => {
                                if(!el.value || el.value == this.$t('form.select')){
                                    el.classList.add('is-invalid');
                                }
                            })
                        }, 500);
                    });
                    return false;
                }
                return true;
            },
            getData() {
                let returndata = {...this.billOfLading}
                return returndata;
            },
            setData(data) {
                this.loaded = false;
                if(data) {
                    console.log(data);
                    this.isEditing = true;
                    this.totals = {'sub': {}, 'vat': {}, 'grand': '-', 'code': ''};
                    this.billOfLading = data;
                    this.billOfLading.has_second_notify = data.second_notify !== null ? 1 : 0;
                    this.billOfLading.fromJob = this.billOfLading.job_booking_id != null && !this.billOfLading.job_data;
                    this.billOfLading.legacy = this.billOfLading.legacy_booking_bl_id != null;
                    if(this.billOfLading.job_data){
                        this.jobItem = {...this.billOfLading.job_data};
                        this.selBooking = this.jobItem.bookings.findIndex(booking => booking.id == this.billOfLading.job_booking_id);
                        delete this.billOfLading.job_data;
                    }
                    const voyage = this.billOfLading.voyage;
                    const portStatus = voyage && voyage.voyage_port_statuses[0] ? voyage.voyage_port_statuses[0] : {status: voyage.voyage_status_id != 3 ? 'open' : 'closed'};
                    this.billOfLading.voyageClosed = Object.keys(portStatus).length > 0 && portStatus.status !== 'open';
                    if(this.billOfLading.shipper != null){
                        if(this.billOfLading.shipper.phone === null) this.billOfLading.shipper.phone = "";
                        if(this.billOfLading.notify === null){
                            this.billOfLading.notify = {
                                "name": this.billOfLading.consignee.name,
                                "email": this.billOfLading.consignee.email,
                                "phone": this.billOfLading.consignee.phone,
                                "address": this.billOfLading.consignee.address,
                                "country_id": this.billOfLading.consignee.country_id
                            }
                        }
                        const countries = dataStore.getters.getCountryNames;
                        ['shipper', 'consignee', 'notify'].forEach((type) => {
                            this.billOfLading[type].defaultISO = this.billOfLading[type].country_id ? countries.find(c => c.id == this.billOfLading[type].country_id).iso : "NL";
                        });
                        if(this.billOfLading.second_notify && this.billOfLading.second_notify.country_id) 
                            this.billOfLading.second_notify.defaultISO = countries.find(c => c.id == this.billOfLading.second_notify.country_id).iso;
                        if(this.billOfLading.port_of_loading && this.billOfLading.port_of_discharge){
                            this.polCountry = this.billOfLading.port_of_loading.country;
                            this.podCountry = this.billOfLading.port_of_discharge.country;
                        }
                    }
                    if(this.billOfLading.lc_port_of_final_destination_name == '0'){
                        this.billOfLading.lc_port_of_final_destination_name = '';
                    }
                    if(this.billOfLading.lc_marks_and_numbers == '0'){
                        this.billOfLading.lc_marks_and_numbers = '';
                    }
                    //if(!this.billOfLading.voyage_name){
                        if(this.billOfLading.voyage){
                            const item = this.billOfLading.voyage;
                            this.billOfLading.voyage_name = item.vessel.name;
                        }
                        else if(this.billOfLading.job_booking && this.billOfLading.job_booking.job_booking_voyage[0]){
                            const item = this.billOfLading.job_booking.job_booking_voyage[0].voyage;
                            this.billOfLading.voyage_name = item.vessel.name;
                            this.billOfLading.voyage = {
                                id: item.id,
                                number: item.number,
                                vessel: {
                                    id: item.vessel.id,
                                    name: item.vessel.name
                                },
                                ports: []
                            };
                        }
                    //}
                    if(!this.billOfLading.port_of_loading_name){
                        if(this.billOfLading.port_of_loading){
                            const item = this.billOfLading.port_of_loading;
                            this.billOfLading.port_of_loading_name = item.name;
                        }
                        else if(this.billOfLading.job_booking && this.billOfLading.job_booking.job_booking_voyage[0]){
                            const item = this.billOfLading.job_booking.job_booking_voyage[0].port_of_loading;
                            this.billOfLading.port_of_loading_name = item.name;
                            this.billOfLading.port_of_loading_id = item.id;
                            this.billOfLading.port_of_loading = {
                                id: item.id,
                                name: item.name
                            };
                        }
                    }
                    if(!this.billOfLading.port_of_discharge_name){
                        if(this.billOfLading.port_of_discharge){
                            const item = this.billOfLading.port_of_discharge;
                            this.billOfLading.port_of_discharge_name = item.name;
                        }
                        else if(this.billOfLading.job_booking && this.billOfLading.job_booking.job_booking_voyage[0]){
                            const item = this.billOfLading.job_booking.job_booking_voyage[0].port_of_discharge;
                            this.billOfLading.port_of_discharge_name = item.name;
                            this.billOfLading.port_of_discharge_id = item.id;
                            this.billOfLading.port_of_discharge = {
                                id: item.id,
                                name: item.name
                            };
                        }
                    }
                    if(this.billOfLading.job_booking){
                        this.pendingUnits = this.billOfLading.job_booking.job_units.filter(unit => data.id != unit.bill_of_lading_id);
                    }
                    if(this.billOfLading.job_units[0]){
                        this.$refs.billofladingref.selectUnit(this.billOfLading.job_units[0]);
                        this.billOfLading.job_units.forEach(unit => {
                            if(unit.use_generated_unit_description === null){
                                unit.use_generated_unit_description = 1;
                            }
                            unit.uuid = unit.id;
                            if(unit.booked_measurement && !unit.volume){
                                unit.volume = (Number(unit.booked_measurement.length) * Number(unit.booked_measurement.width) * Number(unit.booked_measurement.height)) / 1000000;
                            }
                            if(unit.volume && unit.volume.toString().includes('.')){
                                unit.volume = (Number(unit.volume)).toFixed(3);
                            }
                        })
                    }
                    if(this.billOfLading.status_user){
                        const explname = this.billOfLading.status_user.name.split(" ");
                        this.billOfLading.status_namedate = explname[0] + (explname[1] ? (' ' + explname[1][0]) : '') + ', ';
                        this.billOfLading.status_namedate += this.formatDate(this.billOfLading.status_date, "tinyDateAmerican");
                    }
                    if(this.billOfLading.supervisor_approval_id == 2){
                        this.billOfLading.request_approval = 1;
                    }
                    this.billOfLading.update_general_details = true;
                    this.billOfLading.update_job_units = true;
                    this.billOfLading.voyage.ports.sort((a, b) => a.name > b.name ? 1 : -1);
                    this.$refs.billofladingref.polpodOptions = this.billOfLading.voyage.ports;
                    this.$refs.billofladingref.numberAsArray = [this.billOfLading.voyage.number,this.billOfLading.port_of_loading.code,this.billOfLading.port_of_discharge.code,""];
                    this.billOfLading.can_finalize = store.getters.getCompanyHq || this.billOfLading.can_approve_finalize_own || this.billOfLading.operated_by_office_id == store.getters.getCompanyId;
                    this.billOfLading.uneditable = Object.keys(portStatus).length > 0 && portStatus.status !== 'open' && !this.billOfLading.can_finalize;
                    this.$refs.billofladingref.setNumber(this.billOfLading.voyage_id, null, 0, null, false);
                    this.loadData();
                }
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    this.$refs.billofladingref.setShowPaymentOptions();
                    this.$refs.billofladingref.setHeader();
                    this.$refs.billofladingref.setTabs(1);
                    this.$refs.billofladingref.setBranches(false, this.offices);
                    this.loaded = true;
                    
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            let required = document.querySelectorAll("#iteniary input:not([type='checkbox']), #iteniary select");
                            for(let field of required){
                                let value = field.tagName == "SELECT" ? field.value != this.$t('form.select') : field.value;
                                if((field.required || field.classList.contains('required')) && !value){
                                    field.classList.add('is-invalid');
                                }
                            }
                        });
                    });
                }
            },
            reset(isEditing = false) {
                const ref = this.$refs.billofladingref;
                this.$nextTick(() => ref.reset());
                this.loaded = false;
                this.bookingToOpen = null;
                this.jobItem = null;
                this.billOfLading.quote_general_costs = [];
                if(isEditing) {
                    this.isEditing = true;
                    return;
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.loadData();
                    return;
                }
                this.addNewBL();
                if(!this.isEditing && this.$parent.$parent.$parent.selectedVoyage && this.$parent.$parent.$parent.selectedVoyage.id){
                    this.billOfLading.voyage_id = this.$parent.$parent.$parent.selectedVoyage.id;
                    this.billOfLading.voyage = {...this.$parent.$parent.$parent.selectedVoyage};
                    ref.setNumber(this.billOfLading.voyage.id, this.billOfLading.voyage.name.split(' ').at('-1'), 0, this.billOfLading.voyage);
                }
                ref.setShowPaymentOptions();
                ref.setBranches(false, this.offices);
                this.loaded = true;
                this.$nextTick(() => ref.setTabs(1));
            },

            loadData() {
                portService.index().then(response => {
                    this.ports = response.data
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                this.countries = dataStore.getters.getCountryNames;
                clauseService.index().then(response => {
                    this.clauses = response.data
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                colourService.index().then(response => {
                    this.colours = response.data
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                hscodeService.index().then(response => {
                    this.hscodes = response.data
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                this.containerTypes = dataStore.getters.getContainerTypes;
                this.conditionOptions = dataStore.getters.getConditions;
                this.conditionOptions.forEach(dat => dat.name = dat.name.toUpperCase());
                commodityService.index().then(response => {
                    this.commodities = response.data
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                currencyService.indexWithRates().then(response => {
                    this.currencies = response.data;
                    this.currenciesById = {};
                    response.data.forEach(curr => {
                        this.currenciesById[curr.id] = curr;
                    });
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
                relationService.index(null, null, 'surveyor').then(response => {
                    this.surveyers = response.data;
                }).catch(error => console.log('error', error))
                relationService.index(null, null, 'office-branches').then(response => {
                    this.offices = [];
                    response.data.forEach(dat => {
                        this.offices.push({
                            id: dat.office.id,
                            name: dat.name,
                            addresses: dat.addresses
                        })
                    });
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error))
            },

            addNewBL(){
                this.billOfLading = {
                    "id": null,
                    "number": this.$t('form.new'),
                    "job_units": [],
                    "bl_type": 'Original',
                    "payment_option": "PREPAID",
                    "job_booking_id": null,
                    "shipper": {
                        "name": "",
                        "email": "",
                        "phone": "",
                        "address": "",
                        "country_id": null
                    },
                    "consignee": {
                        "name": "",
                        "email": "",
                        "phone": "",
                        "address": "",
                        "country_id": null
                    },
                    "notify": {
                        "name": "",
                        "email": "",
                        "phone": "",
                        "address": "",
                        "country_id": null
                    },
                    "notify_same_as_consignee": 0,
                    "has_second_notify": 0,
                    "one_third_per_shipsmail": 0,
                    "collect_clause": 0,
                    "reference": "",
                    "amount_collect": null,
                    "internal_notes": null,
                    "additional_notes": null,
                    "operated_by_office_id": null,
                    "amount_collect_currency_id": window.defaultCurrency,
                    "in_transit": null,
                    "country_id": null,
                    "is_surveyed": null,
                    "surveyor_id": null,
                    "job_unit_containers": [],
                    "job_booking_voyage_manifest": [],
                    "voyage_id": null,
                    "port_of_loading_id": null,
                    "port_of_discharge_id": null,
                    "port_of_final_destination_id": null,
                    "release_type": 'PRINT',
                    "is_letterofcredit": 0,
                    "lc_marks_and_numbers": "",
                    "lc_description_of_goods": "",
                    "lc_port_of_loading_name": "",
                    "lc_port_of_discharge_name": "",
                    "lc_port_of_final_destination_name": "",
                    "voyage_name": "",
                    "port_of_loading_name": "",
                    "port_of_discharge_name": "",
                    "city": "",
                    "manifest_office_id": null,
                    "bl_date": null,
                    "office_name": null,
                    "is_collectionfee": 0,
                    "printdimensions": 0,
                    "dimensiondecimals": 3,
                    "additionalclause_id": null,
                    "shipper_name": "",
                    "consignee_name": "",
                    "notify_name": "",
                    "representative_name": "",
                    "local_vessel": null,
                    "local_vessel_port": null,
                    "port_of_final_destination": null,
                    "update_general_details": true,
                    "update_job_units": true,
                    "preload_vessel_id": null,
                    "preload_port_id": null,
                    "preload_departure_date": null,
                    "bill_of_lading_status_id": 1,
                    "freight": ""
                };
            },
            
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },

            checkData(){
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isEditing) this.setData(false);
                    else this.reset();
                }
            }

        },
        mounted() {
            for(let i = 1; i <= 100; i++){
                this.quantityOptions.push({id: i, name: i});
            }
        }
    }
</script>